.img-card-img-div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: auto;
    width: auto;
}

.img-card-img-div img{
    height:300px;
    width:300px;
    padding:5px;
    margin:10px;
    margin-right:10px;
}

.image-card{
    height: auto;
    width: auto;
    background-color:bisque;
    margin:8px;
}



.title :hover{
    background-color: azure;
    
}



.view-img-button{
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 10px;
}

p {
    font-size: 15px;
    color: firebrick;
}

@media (max-width: 900px) {
    .img-card-img-div{
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: auto;
        width: auto;
    }

    .img-card-img-div img{
        height:100px;
        width:auto;
        padding:5px;
        margin:10px;
        margin-right:10px;
    }

    .image-card{
        height: auto;
        width: auto;
        background-color:bisque;
        margin: 5px;
    }
    
    .title :hover{
        background-color: azure;
        
    }

    .view-img-button{
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 5px;
    }
    
    p {
        font-size: 10px;
        color: firebrick;
    }
}