@import "react-slideshow-image/dist/styles.css";

h1{
    font-size: 30px;
    color: coral;
    justify-content: center;
    text-align: center;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.slide-container{
    padding: 50px;
    background-color: aliceblue;
    height: auto;
    width: auto;
}

.activity-img img{
    justify-content: center;
    height: auto;
    width: 100%;
    size: auto;

}

@media (max-width: 900px) {
    .slide-container{
        padding: 30px;
        background-color: aliceblue;
        height: auto;
        width: auto;
    }
    
    .activity-img img{
        justify-content: center;
        height: 200px;
        width: 100%;
        size: auto;
    
}
}
