.school-celeb{
    padding: 10px;
    height: auto;
    width: auto;
    justify-content: center;

}

.school-celeb Button {
    position: relative;
    justify-content: center;
    margin-right:16px;
}

