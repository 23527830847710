.volunteer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    min-height: 100vh;
}
.form{
    width: 70%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 1px 1px red, 0em 0 .4em olive;

}
.form-field{
    margin-bottom: 10px;
}
.form-field label{
font-size: 30px;
margin-left: 10px;
font-family: 'GTWalsheimPro-Medium';
}
.form-field input{
    width: 80%;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 4px;
    border-style: none;
    font-size: 15px;
    font-family: 'GTWalsheimPro-Regular';
}

.form-field .btn{
    margin-left: 10px;
    padding:10px 20px;
    border-radius: 6px;
    border: 1px solid transparent;
    font-size: 20px;
    font-family: 'GTWalsheimPro-Medium';
}

.btnpassive{
    color: rgba(55, 52, 52,0.7);
    background-color:rgb(204, 214, 225);
}
.btnactive{
    color: white;
    background-color:rgb(125, 26, 224);
}