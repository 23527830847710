.contact-form{
    height: auto;
    width: auto;
    background-color: pink;
    border: 2px solid palevioletred;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px;
    opacity: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}

.contact-form img{
    grid-column: 2/3;
    justify-items: center;
    align-items: center;
    justify-content: center;  
    border-radius: 50%;
    max-width: 100%;
    place-items: center;
   
}

.contact-form input, textarea{
    min-width: 25vw;
    max-width: 50vw;
    width: 45vw;
    height: 40px;
    font-size:20px;
    padding: 10px;
    border: dotted 2px palevioletred;
    border-radius: 5px;
}

.contact-form label{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: medium;
    padding: 2px;
}

.contact-form hr{
    width:auto;
    
}
.contact-form button{
    padding:15px 25px;
    color:black;
    font-size: 17px;
    background-color:rgb(255, 232, 236);
}

.teachers-div{
    width: 100vw;
    min-height: 100vh;
    height: auto;
    background: pink;
    padding: 20px;
}