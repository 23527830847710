.jumbotron{
        margin-top: 10px;
        height:300px;
        background-size: auto;
        background-repeat: no-repeat;
        width: 100%;
        object-fit:fill;
        opacity: 80%;
}

.info-card{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.info{
    
    background-color: teal;
    height: auto;
    width: 200px;
    margin: 10px 0 0 10px;
    margin-right: 20px;
    opacity: 80%;
}

.info h2{
    padding-left: 25px;
    color: antiquewhite;
    font-size: 50px;
}
.info p {
    padding-left: 25px;
    font-size: 20px;
    color: antiquewhite;
}

.info-card h3{
    color: rgb(76, 164, 86);
    padding: 5px;
}