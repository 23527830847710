.celebrations-gallery{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    height: auto;
    background-color: aliceblue;
    width: auto;
    place-items: center;
}

.celebrations-gallery img{
    margin-right: 20px;
    height: 250px;
    width: 240px;
    padding:10px;
}


@media (max-width: 900px) {

    .celebrations-gallery{
        display: grid;
        grid-template-columns: 1fr 1fr ;
        height: auto;
        background-color: aliceblue;
        width: auto;
        justify-content: center;
        place-items: center;
    }
    
    .celebrations-gallery img{
        margin-right: 10px;
        margin-left: 10px;
        height: 190px;
        width: 190px;
        padding-left:5px;
       
    }


}