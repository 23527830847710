@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
  }
  
  html {
    font-size: 62.5%;
  }
  
  a {
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }
  
  /* 
   
  /* navbar style start  */
  
  .main-nav {
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 15rem 1fr 2fr 1fr 15rem;  /*10rem whitespace at ends then 1fractional unit space for logo and social media links and 2fr for menu */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .org-name{
    grid-column: 1/2;
    align-content: center;
    justify-content: center;
    display: grid;
    text-transform: capitalize;
    color: brown;
    font-size: 11px;
  }

  .logo {
    display: grid;
    /* background-color: #3b5998; */
    grid-column: 2/3;  /*logo col starts from 2nd col ands ar 3rd col hence 2/3*/
    justify-content: left;
    align-items: left;
    padding: 3px;
  }
    
  .logo-img{
    height: 9rem;
    border-radius: 4.5rem;
    max-width: 100%;
  }

  .menu-link {
    grid-column: 3/4;  /*start col from 3 and end at 4*/
  }
  
  .menu-link ul {
    height: 10rem;
    display: flex;
    justify-content: space-around;  /*space between ul items*/
    align-items: center;
  }

  .menu-link ul li{
    font-size: 1.8rem;
  }

  .menu-link ul li a{
    text-transform: capitalize;
    color: black;
    transition: 0.5s;
  }

  .trust-name{
    display: none;
  }

  .social-media {
    grid-column: 4/5;
  }

  .social-media ul {
    height: 10rem;
    display: grid;
    grid-template-columns: 0fr;
    align-items: center;
    justify-content: flex-end;
  }

  .social-media ul li:first-child {
    grid-column: 3/2;
  }
     
  .social-media ul li {
    text-align: right;
    
  }
  
  .social-media ul li a{
    text-transform: capitalize;
    
  }
  
  .navlinkactive{
    font-size: 18px;
    font-weight: 700;
    margin-right: 10px;
    padding: 3px;
    border:1px solid rgba(55, 24, 135,0.7);
    font-family: 'GTWalsheimPro-Thin';
    color: rgb(55, 24, 135);
  }
  .navlinkpassive{
    font-size: 18px;
    font-weight: 700;
    padding: 3px;
    margin-right: 10px;
    border:1px solid white;
    font-family: 'GTWalsheimPro-Thin';
    color: rgba(55, 24, 135,0.7);
  
  }

  @media (max-width: 1080px) {
  
  .main-nav{
    height: 8rem;
    width: auto;
    grid-template-columns: 2rem 1fr 3fr 1fr 2rem;
  }

  .org-name{
    display: none;
  }

  .menu-link ul{
    justify-content: space-around;
  }

  .menu-link ul li{
    font-size: 1.8rem;
    
  }

  .menu-link ul li a{
    text-transform: capitalize;
    color: black;
    transition: 0.5s;
  }

  .logo,
  .social-media ul{
    height: 7rem;
    align-items: center;
    justify-content: center;
  }

  .logo-img{
    height: 7rem;
    border-radius: 3.5rem;
    max-width: 100%;
  }


}
.navlinkactive{
  font-size: 17px;
  padding: 3px;
  margin-right: 8px;

  border:1px solid rgba(55, 24, 135,0.7);
  font-family: 'GTWalsheimPro-Thin';
  background-color: rgb(164, 151, 197);
}
.navlinkpassive{
  font-size: 17px;
  padding: 3px;
  margin-right: 8px;
  
  border:1px solid white;
  font-family: 'GTWalsheimPro-Thin';
  color: rgba(55, 24, 135,0.7);

}

@media (max-width: 998px) {
    
  
    .main-nav{
      height: 7rem;
      grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
      width: auto;
    }

    .menu-link ul{
      justify-content: space-evenly;
    }
  
    .menu-link ul li{
      font-size: 1.8rem;
      
    }
  
    .menu-link ul li a{
      text-transform: capitalize;
      color: black;
      transition: 0.5s;
    }

    .logo
    {
      height: 5rem;
    }
    
    .social-media .social-media-desktop{
        height: 0;
        display: none;
    }

    .logo-img{
      height: 6rem;
      border-radius: 3rem;
      max-width: 100%;
    }

  
  }
  .navlinkactive{
    font-size: 15px;
    padding: 3px;
    margin-right: 8px;
    border:1px solid rgba(55, 24, 135,0.7);
    font-family: 'GTWalsheimPro-Thin';
    color: rgb(55, 24, 135);
  }
  .navlinkpassive{
    font-size: 15px;
    padding: 3px;
    margin-right: 8px;
    border:1px solid white;
    font-family: 'GTWalsheimPro-Thin';
    color: rgba(55, 24, 135,0.7);
  
  } 